import React, { useState, useEffect } from "react";

const styles = {
  linkCta: {
    color: "#060A2E",
    textDecoration: "none",
    fontWeight: "bold",
    borderRadius: "30px",
    background: "#6BE3B3",
    display: "inline-block", // Asegura que el margen funcione correctamente
    textAlign: "center",
    margin: "15px 20px",
  },
  small: {
    fontSize: "14px",
    padding: "8px 16px",
  },
  medium: {
    fontSize: "18px",
    padding: "10px 20px",
    marginTop: "20px", // Añade margen aquí o donde necesites
  },
  big: {
    fontSize: "24px",
    padding: "12px 24px",
  },
  responsiveSmall: {
    fontSize: "12px",
    padding: "6px 12px",
  },
  responsiveMedium: {
    fontSize: "16px",
    padding: "8px 16px",
    marginTop: "15px",
  },
  responsiveBig: {
    fontSize: "20px",
    padding: "10px 20px",
  },
};

function CtaButton({ size, text, link }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const linkStyle = {
    ...styles.linkCta,
    ...(size === "big"
      ? isMobile
        ? styles.responsiveBig
        : styles.big
      : size === "medium"
      ? isMobile
        ? styles.responsiveMedium
        : styles.medium
      : isMobile
      ? styles.responsiveSmall
      : styles.small),
  };

  return (
    <a href={link} style={linkStyle}>
      {text}
    </a>
  );
}

export default CtaButton;
