import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import styled from "styled-components";
import Logo from "../assets/logo.png";
import CtaButton from "../elements/ctaButton";

// Estilos Styled Components

const ToolbarContainer = styled(Toolbar)`
  justify-content: space-between;
`;

const LogoImage = styled.img`
  width: 150px;
`;

const LinkContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const Link = styled.a`
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;

  &.mobile {
    font-size: 25px;
    border-bottom: 3px solid #fff;
    width: 100%;
    text-align: center;
    padding: 15px;
  }
`;

const DrawerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 15px 0px;
  background-color: #060a2e;
  color: #fff;
  border-top: 5px solid #fff;
  height: 200px;
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDrawer = (open) => () => {
    setIsOpen(open);
  };

  return (
    <AppBar
      color="default"
      sx={{ position: "static", boxShadow: "none", background: "none" }}
    >
      <ToolbarContainer>
        <LogoImage src={Logo} alt="Logo" />
        {isMobile ? (
          <>
            <IconButton
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              edge="end"
              sx={{ color: "white" }}
            >
              <MenuIcon sx={{ fontSize: "50px" }} />
            </IconButton>
            <Drawer
              anchor="bottom"
              open={isOpen}
              onClose={toggleDrawer(false)}
              PaperProps={{
                sx: { padding: 0 },
              }}
            >
              <DrawerContainer
                role="presentation"
                onClick={toggleDrawer(false)}
              >
                <Link href="#comofunciona" className="mobile">
                  ¿COMO FUNCIONA?
                </Link>
                <Link href="https://bit.ly/tumenudemo" className="mobile">
                  VER EJEMPLO
                </Link>
                <CtaButton
                  link="https://bit.ly/solicitardemomenudigital"
                  size="medium"
                  text="¡SOLICITAR PRUEBA GRATIS!"
                />
              </DrawerContainer>
            </Drawer>
          </>
        ) : (
          <LinkContainer>
            <Link href="#">¿COMO FUNCIONA?</Link>
            <Link href="https://bit.ly/tumenudemo">VER EJEMPLO</Link>
            <CtaButton
              link="https://bit.ly/solicitardemomenudigital"
              size="small"
              text="¡SOLICITAR PRUEBA GRATIS!"
            />
          </LinkContainer>
        )}
      </ToolbarContainer>
    </AppBar>
  );
};

export default Header;
