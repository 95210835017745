import React from "react";
import styled from "styled-components";
import Subtitle from "../elements/subtitle";
import CheckIcon from "@mui/icons-material/TaskAltOutlined";
import CtaButton from "../elements/ctaButton";

// Estilos Styled Components
const PricesContainer = styled.div`
  display: flex;
  width: 80%;
  border: 3px solid #6be3b3;
  margin: 0 auto;
  border-radius: 40px;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  width: 50%;
  border-right: 3px solid #6be3b3;
  font-weight: bold;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    border-bottom: 3px solid #6be3b3;
  }
`;

const PlanTitle = styled.div`
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  font-size: 50px;

  @media (max-width: 768px) {
    font-size: 30px;
    padding: 20px 10px;
  }
`;

const Price = styled.div`
  height: 50%;
  background: #6be3b3;
  color: #030405;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 0px 0px 0px 35px;
  font-size: 50px;

  @media (max-width: 768px) {
    font-size: 30px;
    border-radius: 0px 0px 0px 0px;
  }
`;

const Features = styled.div`
  text-align: center;
  padding: 20px 0px 20px 0px;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FeaturesTitle = styled.h3`
  font-size: 30px;
  margin: 0px;
  text-decoration: underline;
  margin-bottom: 15px;
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  color: white;
  padding: 10px;
  width: 80%;
  margin: 0 auto;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
`;

const CheckIconStyled = styled(CheckIcon)`
  color: #10b981;
  margin-right: 10px;
`;

const FooterText = styled.div`
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
  border-top: 3px solid #6be3b3;
`;

const Prices = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <Subtitle text="PRECIOS" />
      <PricesContainer>
        <TitleContainer>
          <PlanTitle>
            PLAN <br />
            TODO INCLUIDO
          </PlanTitle>
          <Price>$399 MXN / MES</Price>
        </TitleContainer>
        <Features>
          <FeaturesTitle>¿QUE INCLUYE?</FeaturesTitle>
          <FeaturesList>
            <FeatureItem>
              <CheckIconStyled />
              Productos ilimitados
            </FeatureItem>
            <FeatureItem>
              <CheckIconStyled />
              Pedidos ilimitados
            </FeatureItem>
            <FeatureItem>
              <CheckIconStyled />
              Enlace y QR Personalizados
            </FeatureItem>
            <FeatureItem>
              <CheckIconStyled />
              Cambios en el menú sin costo extra
            </FeatureItem>
            <FeatureItem>
              <CheckIconStyled />
              Acceso a todas las funciones
            </FeatureItem>
          </FeaturesList>
          <FooterText>
            <p style={{ margin: "0px" }}>NO HAY COSTOS DE INSTALACIÓN *</p>
            <br />
            <p style={{ margin: "0px" }}>
              PUEDES CANCELAR EN CUALQUIER MOMENTO *
            </p>
          </FooterText>
        </Features>
      </PricesContainer>
      <br />
      <CtaButton
        link="https://bit.ly/solicitardemomenudigital"
        text="¡SOLICITA TU PRUEBA GRATIS!"
        size="medium"
      />
    </div>
  );
};

export default Prices;
