import React from 'react';

const styles = {
    divider: {
        height: '3px',
        width: '100%',        // Asegura que el divisor ocupe todo el ancho disponible
        backgroundColor: '#6BE3B3',
        margin: '40px 0px 40px 0px'
    }
};

const Divider = () => {
    return (
        <div style={styles.divider}></div>
    );
};

export default Divider;
