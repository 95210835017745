import React from "react";
import Testimonial from "../elements/testimonial";
import sushingone from "./../assets/testimonials/sushingone.jpg"; // Asegúrate de que la ruta es correcta
import vallesalinas from "./../assets/testimonials/vallesalinas.jpg"; // Asegúrate de que la ruta es correcta
import eltablero from "./../assets/testimonials/eltablero.jpg"; // Asegúrate de que la ruta es correcta
import casabaruch from "./../assets/testimonials/casabaruch.jpg"; // Asegúrate de que la ruta es correcta
import baguetteselsebas from "./../assets/testimonials/baguetteselsebas.jpg"; // Asegúrate de que la ruta es correcta
import Subtitle from "../elements/subtitle";

const styles = {
  testimonialsContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
  },
};
function Testimonials() {
  return (
    <>
      <Subtitle text="+100 RESTAURANTES YA" />
      <Subtitle resalted text="CONFIARON EN NOSOTROS" />

      <div style={styles.testimonialsContainer}>
        <Testimonial
          profileImage={sushingone}
          businessName="Sushi N Gone"
          text="Me ayudara bastante este sistema, excelente trabajo, nos encanto"
        />
        <Testimonial
          profileImage={vallesalinas}
          businessName="Carniceria Valle Salinas"
          text="Encantados con el servicio y el menú muchas gracias"
        />
        <Testimonial
          profileImage={eltablero}
          businessName="El Tablero"
          text="Quedo padrisimo el menú!! Me va a permitir que mis clientes vean mas facilmente mis platillos"
        />
        <Testimonial
          profileImage={casabaruch}
          businessName="Casa Baruch"
          text="Excelente Servicioooo, me encanto! Los voy a recomendar a mis amigos que tienen negocios"
        />
        <Testimonial
          profileImage={baguetteselsebas}
          businessName="Baguettes El Sebas"
          text="Me gusto demasiado el menú, esta muy bien!"
        />
      </div>
    </>
  );
}

export default Testimonials;
