import React, { useState, useEffect } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Logo from "./../assets/logo.png"; // Asegúrate de que la ruta al logo es correcta

export const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles = {
    footerContainer: {
      width: "100%",
      textAlign: "center",
      borderTop: "2px solid #6BE3B3",
      paddingTop: "10px",
      marginTop: "30px",
    },
    contentContainer: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "80%",
      margin: "0 auto",
      gap: isMobile ? "20px" : "0", // Aumenta el espacio entre elementos en modo móvil
    },
    logo: {
      width: "150px",
    },
    socialMediaContainer: {
      display: "flex",
      // flexDirection: isMobile ? "column" : "row",
      alignItems: "center",
      gap: "10px",
    },
    link: {
      background: "none",
      border: "none",
      cursor: "pointer",
      color: "inherit",
      textDecoration: "none",
    },
    followUsText: {
      color: "#fff",
      margin: "0 0 10px 0",
    },
    copyRightText: {
      color: "#fff",
      fontWeight: "bold",
      width: "100%",
      borderTop: "2px solid #6BE3B3",
      paddingTop: "20px",
    },
  };

  return (
    <div style={styles.footerContainer}>
      <div style={styles.contentContainer}>
        <img
          src={Logo}
          alt="Logo de Menús Digitales Monterrey"
          style={styles.logo}
        />
        <div>
          <h3 style={styles.followUsText}>Síguenos en:</h3>
          <div style={styles.socialMediaContainer}>
            <a
              href="https://bit.ly/igmenusdigitalesmty"
              style={{ ...styles.link, fontSize: "32px" }} // Aumentar el tamaño de los íconos
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon sx={{ fontSize: "60px" }} />
            </a>
            <a
              href="https://bit.ly/fbmenusdigitalesmty"
              style={{ ...styles.link, fontSize: "32px" }} // Aumentar el tamaño de los íconos
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon sx={{ fontSize: "60px" }} />
            </a>
            <a
              href="https://bit.ly/solicitardemomenudigital"
              style={{ ...styles.link, fontSize: "32px" }} // Aumentar el tamaño de los íconos
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsAppIcon sx={{ fontSize: "60px" }} />
            </a>
          </div>
        </div>
      </div>
      <h2 style={styles.copyRightText}>
        © COPYRIGHT – MENÚS DIGITALES MONTERREY
      </h2>
    </div>
  );
};
