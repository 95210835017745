import React, { useState, useEffect } from "react";

function Subtitle({ text, resalted }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles = {
    subtitle: {
      fontSize: isMobile ? "24px" : "40px", // Tamaño menor para móviles
      marginBottom: "30px",
      textAlign: "center",
      width: "100%",
    },
    resalted: {
      fontSize: isMobile ? "24px" : "40px", // Tamaño menor para móviles
      marginTop: "-20px",
      marginBottom: "30px",
      textAlign: "center",
      width: "100%",
      color: "#6BE3B3",
    },
  };

  return <h3 style={resalted ? styles.resalted : styles.subtitle}>{text}</h3>;
}

export default Subtitle;
