import React from "react";
import StepSection from "../elements/stepSection";
import Step1 from "../assets/steps/step1.gif";
import Step2 from "../assets/steps/step2.gif";
import Step3 from "../assets/steps/step3.gif";
import Step4 from "../assets/steps/step4.png";
import CtaButton from "../elements/ctaButton";
import Subtitle from "../elements/subtitle";

const Steps = () => {
  return (
    <div
      id="comofunciona"
      style={{ textAlign: "center", boxSizing: "border-box" }}
    >
      <Subtitle text="¿COMO FUNCIONA?" />
      <StepSection
        direction="right"
        image={Step1}
        stepNumber="1"
        title="COMPARTE TU MENÚ CON UN ENLACE"
        description="Comparte fácilmente tu menú con un enlace o QR en redes sociales o WhatsApp"
      />
      <StepSection
        direction="left"
        image={Step2}
        stepNumber="2"
        title="SELECCIÓN Y PERSONALIZACIÓN DEL PEDIDO"
        description="Tus clientes eligen sus platos favoritos y personalizan su pedido con opciones especiales directamente desde su dispositivo."
      />
      <StepSection
        direction="right"
        image={Step3}
        stepNumber="3"
        title="CONFIRMACIÓN Y DETALLES DEL PEDIDO"
        description="El cliente coloca todos los detalles de su pedido, su información personal, su dirección, metodo de pago y envia el reporte por WhatsApp."
      />
      <StepSection
        direction="left"
        image={Step4}
        stepNumber="4"
        title="RECIBE EL PEDIDO POR WHATSAPP"
        description="Recibe todos los detalles del pedido en tu whatsapp"
      />
      <CtaButton
        link="https://bit.ly/tumenudemo"
        size="medium"
        text="VER MENÚ DE EJEMPLO"
      />

      {/* Repite para otros pasos */}
    </div>
  );
};

export default Steps;
