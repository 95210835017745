import React from "react";
import Header from "./components/header";
import Hero from "./components/hero";
import Divider from "./elements/divider";
import Steps from "./components/steps";
import Testimonials from "./components/testimonials";
import Prices from "./components/prices";
import { Footer } from "./components/footer";

function App() {
  return (
    <div className="app">
      <Header />
      <Hero />
      <Divider />
      <Steps />
      <Divider />
      <Testimonials />

      <Divider />
      <Prices />
      <Footer />
    </div>
  );
}

export default App;
