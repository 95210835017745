import React from "react";
import styled from "styled-components";
import whatsappLogo from "../assets/whatsapplogo.png";
import CtaButton from "../elements/ctaButton";

// Estilos Styled Components
const HeroContainer = styled.div`
  text-align: center;
  position: relative;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const Resalted = styled.span`
  color: #6be3b3;
`;

const Title = styled.h1`
  font-size: 55px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    font-size: 35px;
    margin-bottom: 20px;
  }
`;

const SubTitle = styled.h2`
  font-size: 28px;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 25px;
  }
`;

const WhatsAppLogo = styled.img`
  position: absolute;
  left: 0; // Posicionamos al extremo izquierdo
  top: 50%; // Central verticalmente
  transform: translate(-50%, -50%) rotate(-20deg); // Inclinación y ajuste
  width: 200px;
  height: 200px;
  @media (max-width: 768px) {
    display: none; // Ocultamos el logo en móviles
  }
`;

const AdviceContainer = styled.div`
  width: 50%;
  margin: 0 auto;
  margin-top: 50px;
  border: 3px solid #fff;
  padding: 10px;
  border-radius: 15px;
  font-weight: bold;
  @media (max-width: 768px) {
    width: 80%;
    margin-top: 30px;
  }
`;

const AdviceText = styled.p`
  font-size: 24px;
  margin: 10px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const AdviceDetail = styled.p`
  font-size: 22px;
  margin: 10px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Hero = () => {
  return (
    <HeroContainer>
      <WhatsAppLogo src={whatsappLogo} alt="WhatsApp logo" />
      <div>
        <Title>
          <Resalted>AUTOMATIZA</Resalted> TUS PEDIDOS <br /> POR WHATSAPP
        </Title>
        <SubTitle>
          UN MENÚ DIGITAL QUE <Resalted>TRANSFORMA</Resalted> <br /> TU SERVICIO
          Y VENTAS
        </SubTitle>
        <CtaButton
          link="https://bit.ly/solicitardemomenudigital"
          size="big"
          text="¡SOLICITA TU PRUEBA GRATUITA!"
        />
      </div>
      <AdviceContainer>
        <AdviceText>¡NO TE QUEDES ATRAS!</AdviceText>
        <AdviceDetail>
          <Resalted>+10 Restaurantes</Resalted> ya están aprovechando nuestra
          demo gratuita esta semana. ¡Asegura tu lugar ahora!
        </AdviceDetail>
      </AdviceContainer>
    </HeroContainer>
  );
};

export default Hero;
