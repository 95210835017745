import React, { useState, useEffect } from "react";
import starsImage from "./../assets/star.png"; // Asegúrate de que la ruta es correcta

const Testimonial = ({ profileImage, text, businessName }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles = {
    testimonialContainer: {
      backgroundColor: "white",
      borderRadius: "15px",
      padding: "20px",
      textAlign: "center",
      width: isMobile ? "100%" : "25%",
      margin: isMobile ? "30px 0" : "30px auto",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      border: "5px solid #6BE3B3", // Color del borde
      position: "relative", // Para posicionar la imagen de perfil
    },
    profileImage: {
      width: "80px",
      height: "80px",
      borderRadius: "50%",
      position: "absolute",
      top: "-40px", // Mueve la imagen hacia arriba
      left: "50%",
      transform: "translateX(-50%)",
      border: "5px solid #6BE3B3", // Borde alrededor de la imagen de perfil
      backgroundColor: "white", // Fondo blanco alrededor de la imagen de perfil
    },
    starsContainer: {
      marginTop: "35px", // Ajusta el margen superior para dar espacio a la imagen de perfil
      marginBottom: "10px",
    },
    starImage: {
      width: "30px",
      height: "30px",
      margin: "0 2px",
    },
    text: {
      fontSize: "20px",
      color: "#060A2E",
      fontWeight: "bold",
      margin: "5px", // Espacio superior para el texto
    },
    businessName: {
      fontSize: "30px",
      color: "#060A2E",
      fontWeight: "bold",
      margin: "10px", // Espacio superior para el texto
      textDecoration: "underline",
    },
  };

  return (
    <div style={styles.testimonialContainer}>
      <img
        src={profileImage}
        alt={"Logo de " + businessName}
        style={styles.profileImage}
      />
      <div style={styles.starsContainer}>
        {Array(5)
          .fill()
          .map((_, index) => (
            <img
              key={index}
              src={starsImage}
              alt="Star"
              style={styles.starImage}
            />
          ))}
      </div>
      <p style={styles.businessName}>{businessName}</p>
      <p style={styles.text}>"{text}"</p>
    </div>
  );
};

export default Testimonial;
