import React, { useState, useEffect } from "react";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    flexDirection: "row", // Dirección por defecto (imagen a la izquierda)
    padding: "10px",
    textAlign: "left",
    width: "100%", // Asegura que el contenedor abarque el 100% del ancho
    boxSizing: "border-box",
  },
  reverseContainer: {
    flexDirection: "row-reverse", // Cambia la dirección (imagen a la derecha)
    textAlign: "right",
  },
  imageContainer: {
    width: "50%",
    order: 0, // Orden inicial para la imagen
  },
  textContainer: {
    width: "50%",
    padding: "0 20px",
    order: 1, // Orden inicial para el texto
  },
  image: {
    width: "100%",
    height: "auto",
  },
  stepNumber: {
    fontSize: "24px",
    color: "#6BE3B3",
    fontWeight: "bold",
  },
  title: {
    fontSize: "30px",
    fontWeight: "bold",
    marginBottom: "5px",
  },
  description: {
    fontSize: "20px",
  },
  responsiveContainer: {
    flexDirection: "column", // Cambio a columna para móviles
    textAlign: "center",
    padding: "10px 0", // Ajuste del padding para móviles
  },
  responsiveImageContainer: {
    width: "100%", // Ancho completo para la imagen en modo móvil
    order: 2, // La imagen va debajo del texto en móviles
  },
  responsiveTextContainer: {
    width: "100%", // Ancho completo para el texto en modo móvil
    order: 1, // El texto va arriba en móviles
  },
  responsiveTitle: {
    fontSize: "24px", // Tamaño más pequeño para móviles
  },
};

const StepSection = ({
  direction = "left",
  image,
  stepNumber,
  title,
  description,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Aplicar el estilo responsive cuando la pantalla es móvil
  const containerStyle = {
    ...styles.container,
    ...(isMobile ? styles.responsiveContainer : {}),
    ...(direction === "right" && !isMobile ? styles.reverseContainer : {}),
  };
  const imageContainerStyle = {
    ...styles.imageContainer,
    ...(isMobile ? styles.responsiveImageContainer : {}),
  };
  const textContainerStyle = {
    ...styles.textContainer,
    ...(isMobile ? styles.responsiveTextContainer : {}),
  };
  const titleStyle = {
    ...styles.title,
    ...(isMobile ? styles.responsiveTitle : {}),
  };

  return (
    <div style={containerStyle}>
      <div style={textContainerStyle}>
        <div style={styles.stepNumber}>PASO #{stepNumber}</div>
        <h2 style={titleStyle}>{title}</h2>
        <p style={styles.description}>{description}</p>
      </div>
      <div style={imageContainerStyle}>
        <img src={image} alt={title} style={styles.image} />
      </div>
    </div>
  );
};

export default StepSection;
